<template>
  <v-container>
    <v-row class="mt-5">
      <v-col cols="12" sm="6" class="py-0 pl-0">
        <v-text-field :label="$t('code-name-passport')" outlined v-model="payments.filters.keyword" />
      </v-col>
      <v-col cols="12" sm="3" class="py-0 pl-0">
        <date :label="$t('date-from')" v-model="payments.filters.from" />
      </v-col>
      <v-col cols="12" sm="3" class="py-0 pl-0">
        <date :label="$t('date-to')" v-model="payments.filters.to" />
      </v-col>
    </v-row>
    <v-row class="payments-container">
      <v-col cols="12" class="px-0 pt-0">
        <datatable :headers="headers" :table="payments" :items="bookings" headerless class="v-data-table--in-row-headers">
          <template #[`item.code`]="{ item }">
            <span>{{ $t("code") }}</span>
            <span>{{ item.code }}</span>
          </template>
          <template #[`item.name`]="{ item }">
            <span>{{ $t("fullname") }}</span>
            <span>{{ `${item.name} ${item.last_name}` }}</span>
          </template>
          <template #[`item.nationality`]="{ item }">
            <span>{{ $t("passport") }}</span>
            <span>{{ `${item.nationality} - ${item.passport}` }}</span>
          </template>
          <template #[`item.in_date`]="{ item }">
            <span>{{ `${$t("from")} - ${$t("to")}` }}</span>
            <span>
              {{ toDateFormat(mergeDateParts(item.in_date, item.in_time), "lll") }}
            </span>
            <span>
              {{ toDateFormat(mergeDateParts(item.out_date, item.out_time), "lll") }}
            </span>
          </template>
          <template #[`item.id`]="{ item }">
            <div class="payment-buttons svg-buttons svg-buttons--4" :data-id="item.id" style="white-space: nowrap">
              <details-dialog :item="item" />

              <booking-form :item="item">
                <template #activator="{ on, attrs }">
                  <v-btn small icon color="success" class="pl-0 pr-0" v-bind="attrs" v-on="on">
                    <v-img max-width="22px" :src="require(`@/assets/edit.svg`)" />
                  </v-btn>
                </template>
              </booking-form>
              <remove-dialog :item="item" @removed="reload" />
            </div>
          </template>
        </datatable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Datatable from "../components/Datatable";
import Date from "../components/Date";
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import payments from "../../requests/payments";
import RemoveDialog from "./RemoveDialog";
import DetailsDialog from "./DetailsDialog";
import date from "../../mixins/date";
import BookingForm from "../Bookings/Form";

export default {
  mixins: [date],
  components: {
    Datatable,
    Date,
    RemoveDialog,
    DetailsDialog,
    BookingForm,
  },
  data: () => ({
    page: 1,
    payments: {
      filters: {
        keyword: null,
        from: null,
        to: null,
      },
      loading: false,
      options: {},
      items: [],
      totalItems: 0,
      draw: 0,
    },
    loading: false,
    timeout: null,
  }),
  computed: {
    ...mapGetters({
      bookings: "bookings/bookings",
    }),
    headers: function () {
      return [
        {
          text: "",
          value: "code",
        },
        {
          text: "",
          value: "name",
        },
        {
          text: "",
          value: "nationality",
        },
        {
          text: "",
          value: "in_date",
        },
        {
          text: "",
          value: "id",
          width: "20px",
        },
      ];
    },
  },
  watch: {
    page: function () {
      this.reload();
    },
    bookings: {
      handler: function (value) {
        this.payments.items = value;
      },
      deep: true,
    },
    "payments.options.page": {
      handler: function (value) {
        this.page = value;
      },
      deep: true,
    },
    "payments.filters": {
      handler: async function () {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(
          function () {
            if (this.page != 1) {
              this.page = 1;
            } else {
              this.reload();
            }
          }.bind(this),
          1000
        );
      },
      deep: true,
    },
  },
  created: async function () {
    dayjs.extend(utc);
    this.payments.filters.from = dayjs().format("YYYY-MM-DD");
  },
  methods: {
    fromDate: function (date) {
      return dayjs(date);
    },
    statusToList: function (item) {
      return payments.statusToList(item);
    },
    reload: async function () {
      const params = {};
      if (this.payments.filters) {
        if (this.payments.filters.from) {
          params.from = dayjs(this.payments.filters.from).format("YYYY-MM-DD");
        }
        if (this.payments.filters.to) {
          params.to = dayjs(this.payments.filters.to).format("YYYY-MM-DD");
        }
        if (this.payments.filters.keyword) {
          params.keyword = this.payments.filters.keyword
        }
      }
      params.length = this.payments.options.itemsPerPage ?? 20;
      params.start = (this.page - 1) * params.length;

      const response = await this.$store.dispatch("bookings/loadBookings", params);
      this.payments.items = response.data;
      this.payments.totalItems = response.length;
    },
  },
};
</script>

<style lang="scss" scoped></style>
