<template>
  <v-dialog v-model="dialog" max-width="600px" width="100%">
    <template #activator="{ on, attrs }">
      <slot name="activator" v-bind:attrs="attrs" v-bind:on="on">
        <v-btn small icon class="ml-2 pl-0 pr-0" v-bind="attrs" v-on="on">
          <v-img max-width="22px" :src="require(`@/assets/details.svg`)" />
        </v-btn>
      </slot>
    </template>

    <v-card color="white">
      <v-card-title class="text-h5">
        {{ $t("bookings-.new") }}
        <v-btn icon text elevation="0" color="red" class="clear-button" @click="dialog = false">
          <v-img max-width="20px" :src="require('@/assets/clear.svg')" />
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-4">
        <validation-observer v-slot="{ valid }">
          <v-form @submit.prevent="submit">
            <v-row>
              <v-col cols="12" md="6" class="pb-0">
                <validation-provider v-slot="{ errors }" :name="$t('name').toLowerCase()" :rules="{ required: true }">
                  <v-text-field v-model="request.name" outlined :label="$t('name')" :error-messages="errors" />
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6" class="pb-0">
                <validation-provider v-slot="{ errors }" :name="$t('last-name').toLowerCase()"
                  :rules="{ required: true }">
                  <v-text-field v-model="request.last_name" outlined :label="$t('last-name')" :error-messages="errors" />
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6" class="pb-0">
                <validation-provider v-slot="{ errors }" :name="$t('nationality').toLowerCase()"
                  :rules="{ required: true }">
                  <v-text-field v-model="request.nationality" outlined :label="$t('nationality')"
                    :error-messages="errors" />
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6" class="pb-0">
                <validation-provider v-slot="{ errors }" :name="$t('passport').toLowerCase()" :rules="{ required: true }">
                  <v-text-field v-model="request.passport" outlined :label="$t('passport')" :error-messages="errors" />
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6" class="pb-0">
                <validation-provider v-slot="{ errors }" :name="$t('payment-method').toLowerCase()"
                  :rules="{ required: true }">
                  <v-text-field v-model="request.pay_method" outlined :label="$t('payment-method')"
                    :error-messages="errors" />
                </validation-provider>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-card outlined class="px-0 py-0">
                  <v-card-text class="pt-2">
                    <div>{{ $t("arrival") }}</div>
                    <v-row class="mt-3">
                      <v-col cols="12" class="pb-0">
                        <validation-provider v-slot="{ errors }" :name="$t('airline').toLowerCase()"
                          :rules="{ required: true }">
                          <v-text-field v-model="request.in_fly" outlined :label="$t('airline')"
                            :error-messages="errors" />
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" md="6" class="pb-0">
                        <v-menu transition="scale-transition" offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <validation-provider v-slot="{ errors }" :name="$t('date').toLowerCase()"
                              :rules="{ required: true }">
                              <v-text-field v-model="request.in_date" :label="$t('date')" readonly outlined v-bind="attrs"
                                v-on="on" :error-messages="errors">
                                <template #prepend-inner>
                                  <v-img max-width="20px" :src="require('@/assets/calendar.svg')" />
                                </template>
                              </v-text-field>
                            </validation-provider>
                          </template>
                          <v-date-picker v-model="request.in_date" />
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="6" class="pb-0">
                        <v-menu ref="menu" v-model="in_time_open" :close-on-content-click="false" :nudge-right="40"
                          :return-value.sync="request.time" transition="scale-transition" offset-y max-width="290px"
                          min-width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <validation-provider v-slot="{ errors }" :name="$t('time').toLowerCase()"
                              :rules="{ required: true }">
                              <v-text-field v-model="request.in_time" :label="$t('time')"
                                prepend-inner-icon="mdi-clock-time-four-outline" readonly outlined v-bind="attrs"
                                v-on="on" :error-messages="errors" />
                            </validation-provider>
                          </template>
                          <v-time-picker v-if="in_time_open" v-model="request.in_time" full-width
                            @click:minute="$refs.menu.save(request.in_time)"></v-time-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-card outlined class="px-0 py-0">
                  <v-card-text class="pt-2">
                    <div>{{ $t("departure") }}</div>
                    <v-row class="mt-3">
                      <v-col cols="12" class="pb-0">
                        <validation-provider v-slot="{ errors }" :name="$t('airline').toLowerCase()"
                          :rules="{ required: true }">
                          <v-text-field v-model="request.out_fly" outlined :label="$t('airline')"
                            :error-messages="errors" />
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" md="6" class="pb-0">
                        <v-menu transition="scale-transition" offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <validation-provider v-slot="{ errors }" :name="$t('date').toLowerCase()"
                              :rules="{ required: true }">
                              <v-text-field v-model="request.out_date" :label="$t('date')" readonly outlined
                                v-bind="attrs" v-on="on" :error-messages="errors">
                                <template #prepend-inner>
                                  <v-img max-width="20px" :src="require('@/assets/calendar.svg')" />
                                </template>
                              </v-text-field>
                            </validation-provider>
                          </template>
                          <v-date-picker v-model="request.out_date" />
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="6" class="pb-0">
                        <v-menu ref="menu" v-model="out_time_open" :close-on-content-click="false" :nudge-right="40"
                          :return-value.sync="request.time" transition="scale-transition" offset-y max-width="290px"
                          min-width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <validation-provider v-slot="{ errors }" :rules="{ required: true }"
                              :name="$t('time').toLowerCase()">
                              <v-text-field v-model="request.out_time" :label="$t('time')"
                                prepend-inner-icon="mdi-clock-time-four-outline" outlined readonly v-bind="attrs"
                                v-on="on" :error-messages="errors" />
                            </validation-provider>
                          </template>
                          <v-time-picker v-if="out_time_open" v-model="request.out_time" full-width
                            @click:minute="$refs.menu.save(request.out_time)"></v-time-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" class="mt-2">
                <v-checkbox v-model="request.transfer" :label="$t('has-transfer')" hide-details class="mt-0" />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-right">
                <v-btn color="primary" type="submit" :disabled="!valid" elevation="0" block>
                  {{ $t("submit") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import payments from "../../requests/payments";
import date from "../../mixins/date";

export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  mixins: [date],
  components: {},
  data: () => ({
    dialog: false,
    in_time_open: false,
    out_time_open: false,
    request: {
      name: "",
      last_name: "",
      passport: "",
      nationality: "",
      in_date: "",
      out_date: "",
      in_fly: "",
      out_fly: "",
      in_time: "",
      out_time: "",
      pay_method: "",
      transfer: false,
    },
  }),
  computed: {},
  created: function () {
    if (this.item) {
      const item = Object.assign({}, this.item);
      item.in_date = this.toDateFormat(item.in_date, "YYYY-MM-DD");
      item.out_date = this.toDateFormat(item.out_date, "YYYY-MM-DD");
      this.request = item;
    }
  },
  methods: {
    async submit() {
      try {
        const params = Object.assign({}, this.request);
        params.in_time = this.dateFromTime(params.in_time).format("HH:mm:ss");
        params.out_time = this.dateFromTime(params.out_time).format("HH:mm:ss");
        let response = null;
        if (params.id) {
          response = await payments.update(params);
        } else {
          response = await payments.create(params);
        }

        if (response.data.code) {
          await payments.downloadDocument(response.data);
        }

        this.$store.dispatch(
          "notificator/success",
          this.$t("your-password-has-been-changed")
        );
        this.$store.dispatch("bookings/loadBookings", {});

        this.dialog = false;
      } catch (error) {
        await this.$store.dispatch("notificator/errorResponse", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog__content {
  .clear-button {
    position: absolute;
    top: 30px;
    right: 40px;
  }

  .v-card {
    border-radius: 24px;
    border: solid rgba(0, 0, 0, 0.42) 1px !important;
    padding: 20px;

    .v-card__title {
      color: #000000;
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;
      padding-bottom: 20px;
    }

    .v-card__text {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #1a1a1a;
    }
  }
}
</style>
