<template>
  <v-row class="mt-5">
    <v-col cols="12" sm="6">
      <slot>
        <h2>{{ $t("dashboard") }}</h2>
        <p>{{ $t("dashboard_welcome") }}</p>
      </slot>
    </v-col>
    <v-col cols="12" sm="6" class="heading-actions">
      <slot name="actions">
        <booking-form>
          <template #activator="{ on, attrs }">
            <v-btn color="primary" elevation="0" v-bind="attrs" v-on="on">
              <v-img max-width="22px" :src="require(`@/assets/plus.svg`)" class="mr-2" />
              {{ $t("bookings-.new") }}
            </v-btn>
          </template>
        </booking-form>
      </slot>
      <v-btn text icon class="ml-5">
        <v-icon>mdi-bell-outline</v-icon>
      </v-btn>
      <v-btn
        v-if="!$vuetify.breakpoint.mdAndUp"
        text
        dark
        class="ml-5"
        @click.stop="$store.dispatch('switchDrawer', true)"
      >
        <v-icon color="#2d6283">mdi-menu</v-icon>
      </v-btn>
      <language-switcher inline class="ml-3" />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import LanguageSwitcher from "./LanguageSwitcher";
import users from "../../mixins/users";
import BookingForm from "../Bookings/Form";

export default {
  mixins: [users],
  components: {
    LanguageSwitcher,
    BookingForm,
  },
  computed: {
    ...mapGetters({
      getUserInfo: "user/userInfo",
    }),
  },
  data: () => ({}),
  async created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.heading-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
