<template>
  <v-dialog v-model="dialog" width="500">
    <template #activator="{ on, attrs }">
      <slot name="activator" v-bind:attrs="attrs" v-bind:on="on">
        <v-btn color="red lighten-2" dark outlined elevation="0" v-bind="attrs" v-on="on">
          Click Me
        </v-btn>
      </slot>
    </template>

    <v-card color="white">
      <v-card-title class="text-h5">
        <slot name="title">{{ $t("confirm") }}</slot>

        <v-btn icon text elevation="0" color="red" class="clear-button" @click="discard">
          <v-img max-width="20px" :src="require('@/assets/clear.svg')" />
        </v-btn>
      </v-card-title>

      <v-card-text>
        <slot>
          {{ $t("this-action-requires-confirmation") }}
        </slot>
      </v-card-text>

      <v-card-actions v-if="!hideActions">
        <slot name="actions">
          <v-row>
            <v-col>
              <v-btn block elevation="0" outlined color="red" @click="discard">
                {{ $t("discard") }}
              </v-btn>
            </v-col>
            <v-col>
              <v-btn block elevation="0" color="primary" @click="confirm">
                {{ $t("confirm") }}
              </v-btn>
            </v-col>
          </v-row>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
  }),
  watch: {
    show: function (value) {
      if (value) {
        this.dialog = !value;
      }
    },
  },
  async created() {},
  methods: {
    discard: async function () {
      await this.$emit("discarded");
      this.dialog = false;
    },
    confirm: async function () {
      await this.$emit("confirmed");
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog__content {
  .clear-button {
    position: absolute;
    top: 30px;
    right: 40px;
  }
  .v-card {
    box-shadow: 0px 40px 80px rgba(0, 0, 0, 0.04);
    border-radius: 24px;
    border: solid red 1px;
    padding: 20px 40px 20px 40px;
    .v-card__title {
      color: #000000;
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;
      padding-bottom: 20px;
    }
    .v-card__text {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #1a1a1a;
    }
  }
}
</style>
