<template>
  <v-menu transition="scale-transition" offset-y min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <validation-provider v-slot="{ errors }" :name="label.toLowerCase()" :rules="{ required: required }">
        <v-text-field v-model="date" :label="label" readonly outlined v-bind="attrs" v-on="on" :error-messages="errors"
          :clearable="!required">
          <template #prepend-inner>
            <v-img max-width="20px" :src="require('@/assets/calendar.svg')" />
          </template>
        </v-text-field>
      </validation-provider>
    </template>
    <v-date-picker v-model="date" />
  </v-menu>
</template>

<script>
export default {
  props: {
    value: String,
    label: {
      type: String,
      default: "date"
    },
    required: {
      type: Boolean,
      default: false
    },
  },
  emits: ['input'],
  data: () => ({
    dialog: false,
    date: null,
  }),
  watch: {
    date: function (value) {
      this.$emit('input', value)
    }
  },
  computed: {},
  created: function () {
    this.date = this.value
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped></style>