<template>
  <v-dialog v-model="dialog" width="500">
    <template #activator="{ on, attrs }">
      <v-btn small icon class="ml-2 pl-0 pr-0" v-bind="attrs" v-on="on">
        <v-img max-width="22px" :src="require(`@/assets/details.svg`)" />
      </v-btn>
    </template>

    <v-card color="white">
      <v-card-title class="text-h5">
        {{ $t("details") }}
        <v-btn icon text elevation="0" color="red" class="clear-button" @click="dialog = false">
          <v-img max-width="20px" :src="require('@/assets/clear.svg')" />
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <attribute>
              <template #name>{{ $t("fullname") }}</template>
              {{ `${item.name} ${item.last_name}` }}
            </attribute>
          </v-col>
          <v-col cols="6">
            <attribute>
              <template #name>{{ $t("code") }}</template>
              {{ item.code }}
            </attribute>
          </v-col>
          <v-col cols="6">
            <attribute>
              <template #name>{{ $t("payment-method") }}</template>
              {{ item.pay_method }}
            </attribute>
          </v-col>
          <v-col cols="6">
            <attribute>
              <template #name>{{ $t("passport") }}</template>
              {{ item.passport }}
            </attribute>
          </v-col>
          <v-col cols="6">
            <attribute>
              <template #name>{{ $t("nationality") }}</template>
              {{ item.nationality }}
            </attribute>
          </v-col>
          <v-col cols="6">
            <attribute>
              <template #name>{{ $t("in-fly") }}</template>
              {{ item.in_fly }}
            </attribute>
          </v-col>
          <v-col cols="6">
            <attribute>
              <template #name>{{ $t("out-fly") }}</template>
              {{ item.out_fly }}
            </attribute>
          </v-col>
          <v-col cols="6">
            <attribute>
              <template #name>{{ $t("in") }}</template>
              {{ toDateFormat(mergeDateParts(item.in_date, item.in_time), "lll") }}
            </attribute>
          </v-col>
          <v-col cols="6">
            <attribute>
              <template #name>{{ $t("out") }}</template>
              {{ toDateFormat(mergeDateParts(item.out_date, item.out_time), "lll") }}
            </attribute>
          </v-col>
          <v-col cols="6">
            <attribute>
              <template #name>{{ $t("transfer") }}</template>
              {{ $t(item.transfer ? "yes" : "no") }}
            </attribute>
          </v-col>
          <v-col cols="6">
            <attribute>
              <template #name>{{ $t("registered") }}</template>
              {{ toDateFormat(item.created_at, "lll") }}
            </attribute>
          </v-col>
          <v-col cols="12">
            <v-btn color="primary" elevation="0" @click="downloadFile">
              {{ $t("generate-voucher") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Attribute from "../components/Attribute";
import date from "../../mixins/date";
import payments from "../../requests/payments";

export default {
  mixins: [date],
  components: {
    Attribute,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    downloadFile: async function () {
      try {
        await payments.downloadDocument(this.item);
      } catch (e) {
        console.error(e);
        await this.$store.dispatch("notificator/errorResponse", e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog__content {
  .clear-button {
    position: absolute;
    top: 30px;
    right: 40px;
  }

  .v-card {
    box-shadow: 0px 40px 80px rgba(0, 0, 0, 0.04);
    border-radius: 24px;
    border: solid red 1px;
    padding: 20px 40px 20px 40px;

    .v-card__title {
      color: #000000;
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;
      padding-bottom: 20px;
    }

    .v-card__text {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #1a1a1a;
    }
  }
}
</style>
