import * as dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

var date = {
  created: function () {
    dayjs.extend(localizedFormat)
  },
  methods: {
    toDate: function (date) {
      return dayjs(date)
    },
    toDateFormat: function (date, format) {
      return dayjs(date).format(format)
    },
    mergeDateParts: function (date, time) {
      const timeParts = time.split(':')
      return dayjs(date).set('hour', timeParts[0]).set('minute', timeParts[1])
    },
    dateFromTime: function (time) {
      const timeParts = time.split(':')
      return dayjs().set('hour', timeParts[0]).set('minute', timeParts[1])
    },
  }
}
export default date;