<template>
  <v-data-table
    :headers="headers"
    :items="table.items"
    :options.sync="table.options"
    :server-items-length="table.totalItems"
    :loading="table.loading"
    :class="`elevation-0 ${headerless ? 'v-data-table-headerless' : ''}`"
    :hide-default-footer="table.items.length == 0"
    :footer-props="{ 'items-per-page-options': [5, 10, 20, 50, 100] }"
  >
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    table: {
      type: Object,
      default: () => ({}),
    },
    headerless: {
      type: Boolean,
      default: false,
    },
  },
  async created() {},
};
</script>
